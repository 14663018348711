<script setup lang="ts">
import type { GlobalAnnouncement } from "~/types/api"

import { tv } from "tailwind-variants"

import { getGlobalAnnouncements } from "~/api/requests/globalAnnouncements"
import { useAnnouncementsStore } from "~/stores/announcements"

const devBanner: GlobalAnnouncement = {
  id: "00000000-0000-0000-0000-000000000001",
  createdAt: "2024-01-01T00:00:00Z",
  expiresAt: undefined,
  title: "You are in development mode",
  description: undefined,
  isDismissible: false,
  isPublic: true,
  showForNewUsers: true,
  type: "error",
  url: undefined,
}

const stagingBanner: GlobalAnnouncement = {
  id: "00000000-0000-0000-0000-000000000002",
  createdAt: "2024-01-01T00:00:00Z",
  expiresAt: undefined,
  title: "You are in staging mode",
  description: undefined,
  isDismissible: false,
  isPublic: true,
  showForNewUsers: true,
  type: "warning",
  url: undefined,
}

const { data: fetchedAnnouncements } = await useDeimosQuery({
  queryKey: ["announcements"],
  queryFn: getGlobalAnnouncements,
  staleTime: 1000 * 60, // 60 seconds
})
const { user } = useAuthStore()
const { dismissedAnnouncements, dismissAnnouncement } = useAnnouncementsStore()

const filteredAnnouncements = computed(() => {
  const announcements =
    fetchedAnnouncements.value?.filter((announcement) => {
      // If the announcement is expired, don't show it
      if (
        announcement.expiresAt &&
        new Date(announcement.expiresAt).getTime() <= Date.now()
      ) {
        return false
      }

      // If the announcement is dismissible and has been dismissed, don't show it
      if (
        announcement.isDismissible &&
        dismissedAnnouncements.includes(announcement.id)
      ) {
        return false
      }

      // If the announcement isn't public and the user isn't logged in, don't show it
      if (!announcement.isPublic && !user) return false

      if (user && !announcement.showForNewUsers) {
        const announcementDate = new Date(announcement.createdAt).getTime()
        const userCreatedDate = new Date(user.createdAt).getTime()

        // If the user was created after the announcement, don't show it
        if (userCreatedDate > announcementDate) {
          return false
        }
      }

      return true
    }) ?? []

  if (import.meta.dev) {
    return [devBanner, ...announcements]
  } else if (useRuntimeConfig().public.environment === "staging") {
    return [stagingBanner, ...announcements]
  }

  return announcements
})

const classes = tv({
  base: "w-full py-2 px-4 grid grid-cols-[auto,1fr,auto] gap-8 items-center",
  variants: {
    type: {
      warning: "bg-[#ffde8a] text-black",
      maintenance: "bg-[#ffde8a] text-black",
      error: "bg-red-600 text-white",
      success: "bg-forest-400 bg-opacity-20 text-black",
      info: "bg-ebony-300 bg-opacity-20 text-black",
    },
  },
})
</script>

<template>
  <div v-if="filteredAnnouncements.length > 0" class="bg-white">
    <div
      v-for="announcement of filteredAnnouncements"
      :key="announcement.id"
      :class="classes({ type: announcement.type as any })">
      <span>
        <span v-if="announcement.type === 'maintenance'" class="font-bold">
          Maintenance:
        </span>
        {{ announcement.title }}
      </span>
      <span class="opacity-75">
        {{ announcement.description }}
      </span>
      <div
        v-if="announcement.isDismissible || announcement.url"
        class="flex items-center space-x-3">
        <a
          v-if="announcement.url"
          :href="announcement.url"
          target="_blank"
          rel="noreferrer"
          class="hover:underline">
          Learn More
        </a>
        <button
          v-if="announcement.isDismissible"
          class="bg-black bg-opacity-10 rounded"
          @click="dismissAnnouncement(announcement.id)">
          <Icon class="w-8 h-8" name="basil:cross-outline" />
        </button>
      </div>
    </div>
  </div>
</template>
