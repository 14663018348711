export const useAnnouncementsStore = defineStore("announcements", {
  state: () => ({
    dismissedAnnouncements: [] as string[],
  }),
  actions: {
    dismissAnnouncement(id: string) {
      this.dismissedAnnouncements.push(id)
    },
  },
  persist: {
    key: "announcements",
  },
})
